<template>
  <div class="purchase page">
    <div class="content">
      <div class="page_header">
        <h1>Закупка</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Purchase",
  created(){
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  }
}
</script>
